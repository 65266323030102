import React, { useEffect, useState } from "react";
import "./sidefund.css";
import { ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";

const Sidefund = () => {
  const [apprFunds, setApprFunds] = useState([]);

  const getApprFund = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getapprFund")
        .then((res) => {
          if (res.status === 200) {
            setApprFunds(res.data.apprFund[0]);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getApprFund();
  }, []);

  const balanceAmt = Math.max(apprFunds.goalAmt - apprFunds.raisedAmt, 0);

  const formattedGoalAmt = apprFunds.goalAmt?.toLocaleString();
  const formattedRaisedAmt = apprFunds.raisedAmt?.toLocaleString();
  const formattedBalanceAmt = balanceAmt?.toLocaleString();

  const progressPercentage = apprFunds.goalAmt > 0 
    ? (apprFunds.raisedAmt / apprFunds.goalAmt) * 100 
    : 0;

  // Calculate percentages for each section
  const goalPercentage = Math.min((apprFunds.goalAmt / apprFunds.goalAmt) * 100, 100);
  const receivedPercentage = Math.min((apprFunds.raisedAmt / apprFunds.goalAmt) * 100, 100);
  const stillExpectedPercentage = 100 - receivedPercentage;

  return (
    <div className="sidefund-container">
      <div className="sidefund p-4">
        <Link className="contribute-btn" style={{ textDecoration: "none" }}>
          {/* Add the link to contribute if needed */}
        </Link>

        <div className="text-center mb-3">
          <div className="text-center">
            <h2 style={{backgroundColor:'black',color:'white',fontSize:'25px',borderRadius:'5px',padding:'5px'}}>
              Contribute Now
            </h2>
          </div>        
          <div className="my-3" style={{ fontSize: "32px" }}>
              Goal Of: ₹ {formattedGoalAmt}
          </div>
          <div style={{ fontSize: "20px" }}>
            Received: ₹ {formattedRaisedAmt}
          </div>
          <div style={{ fontSize: "20px" ,marginTop:'5px'}}>
            Still Expected: ₹ {formattedBalanceAmt}
          </div>
        </div>

        {/* Progress Bar */}
        <div className="progress-container my-3">
          <ProgressBar className="mb-3" style={{ height: "20px" }}>
            {/* Goal Amount - Green */}
            <ProgressBar
              striped
              now={goalPercentage}
              variant="success"
              key={1}
              style={{ width: `${goalPercentage}%` }}
            />
            {/* Received Amount - Yellow */}
            <ProgressBar
              striped
              now={receivedPercentage}
              variant="warning"
              key={2}
              style={{ width: `${receivedPercentage}%` }}
            />
            {/* Still Expected Amount - Red */}
            <ProgressBar
              striped
              now={stillExpectedPercentage}
              variant="danger"
              key={3}
              style={{ width: `${stillExpectedPercentage}%` }}
            />
          </ProgressBar>
        </div>
     
      </div>
    </div>
  );
};

export default Sidefund;
