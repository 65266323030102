import React, { useEffect, useState } from "react";
import Header from "../Header/Header";

import Gallery from "./Gallery";
import Stack from "react-bootstrap/Stack";
import Footer from "../Footer/Footer";
import { Container, Row, Col, Image } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Navigation from "../../Navigation";
const Gallerymain = () => {
  
  useEffect(()=>{
      window.scrollTo(0,0);
    },[])
    
  const [galleries, setgalleries] = useState([]);
  const getImages = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getgallery")
        .then((res) => {
          if (res.status === 200) {
            setgalleries(res.data.galleryList);
          }
        });
    } catch (error) {
      console.log(error);
      setgalleries([]);
    }
  };
  /* ======================================================================================================== */
  useEffect(() => {
    getImages();
  }, []);

  return (
    <div>
      <Header />
      <Navigation />

      {/* <div className="tm-hero d-flex justify-content-center align-items-center" data-parallax="scroll"  
    data-image-src="./slid.jpg"
    
    >
      <img src="./slid.jpg"></img>
     
    </div> */}

<div className="container-fluid tm-container-content tm-mt-60">
  <div className="row mb-4 text-center">
    <h2 className="col-12 tm-text-primary">Gallery</h2>
  </div>
  <div className="row tm-mb-90 tm-gallery">
    {galleries.map((item1) => {
      return (
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <figure className="effect-ming tm-video-item d-flex justify-content-center">
            <div className="w-100" style={{ height: "250px", overflow: "hidden" }}>
              <img
                src={`https://srivinayaka.info/Gallery/${item1?.galleryimg}`}
                alt="Image"
                className="img-fluid w-100 h-100 object-fit-cover"
              />
            </div>
          </figure>
          <div className="d-flex justify-content-between tm-text-gray">
            <span className="tm-text-gray-light">
              {moment(item1?.createdAt).format("DD MMMM YYYY")}
            </span>
          </div>
        </div>
      );
    })}
  </div>
</div>


      <Footer />
    </div>
  );
};

export default Gallerymain;
