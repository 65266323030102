import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "./formv.css";
import { Padding } from "@mui/icons-material";
import { api_baseURL } from "../../env";
import axios from "axios";
import moment from "moment/moment";
import { Country, State, City } from "country-state-city";
const Formv = () => {
  {
    /* ========================================================================================================== */
  }
  // volunteer registration
  const formdata = new FormData();
  const [VfName, setVfName] = useState("");
  const [vLName, setvLName] = useState("");
  const [So, setSo] = useState("");
  const [Address, setAddress] = useState("");
  const [Street, setStreet] = useState("");
  const [taluka, settaluka] = useState("");
  const [dist, setdist] = useState("");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [phno, setphno] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [email, setemail] = useState("");
  const [dob, setdob] = useState("");
  const [age, setage] = useState();
  const [education, seteducation] = useState("");
  const [occupation, setoccupation] = useState("");
  const [volunteeringInterest, setvolunteeringInterest] = useState("");
  const [refferedBy, setrefferedBy] = useState("");
  const [donation, setdonation] = useState("");
  const [gpsLoc, setgpsLoc] = useState("");
  const [aadhar, setaadhar] = useState("");
  const [pan, setpan] = useState("");
  const [vPic, setvPic] = useState("");
  const [vIDCard, setvIDCard] = useState("");
  const [isTermsandcondAcc, setisTermsandcondAcc] = useState(false);

  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(country);
  const CityList = City.getCitiesOfState(country, state);

  const registerVolunteer = async (e) => {
    e.preventDefault();
    if (!isTermsandcondAcc) {
      return alert("Please select terms and conditions...");
    }
    formdata.set("vfName", VfName);
    formdata.set("vlName", vLName);
    formdata.set("so", So);
    formdata.set("address", Address);
    formdata.set("street", Street);
    formdata.set("taluka", taluka);
    formdata.set("dist", dist);
    formdata.set("country", country);
    formdata.set("state", state);
    formdata.set("city", city);
    formdata.set("pincode", pincode);
    formdata.set("phno", phno);
    formdata.set("whatsapp", whatsapp);
    formdata.set("email", email);
    formdata.set("dob", dob);
    formdata.set("age", age);
    formdata.set("education", education);
    formdata.set("occupation", occupation);
    formdata.set("volunteeringInterest", volunteeringInterest);
    formdata.set("refferedBy", refferedBy);
    formdata.set("donation", donation);
    formdata.set("gpsLoc", gpsLoc);
    formdata.set("isTermsandcondAcc", isTermsandcondAcc);
    formdata.set("aadhar", aadhar);
    formdata.set("pan", pan);
    formdata.set("vPic", vPic);

    try {
      const config = {
        url: "/volunteer/postVolunteer",
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // console.log("mmm", vPic);

  {
    /* ========================================================================================================== */
  }

  const [volunteerList, setvolunteerList] = useState([]);
  const allVolunteer = async () => {
    try {
      let res = await axios.get(
        `https://srivinayaka.info/api/volunteer/getAllVolunteer`
      );
      if (res.status === 200) {
        setvolunteerList(res.data.allVolunteer);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  {
    /* ========================================================================================================== */
  }

  useEffect(() => {
    allVolunteer();
  }, []);
  useEffect(() => {
    const getAge = moment().diff(dob, "years", false);
    setage(getAge);
  }, [dob]);

  return (
    <div className="formv">
      <div className="formvol container">
        <Form className="volunteer-f">
          <div
            style={{
              textTransform: "uppercase",
              textAlign: "center",
              fontSize: "28px",
              fontFamily: "sans-serif",
              fontWeight: "500",
              background: "#0199FF",
              marginBottom: "50px",
              color: "white",
            }}
          >
            Volunteer Registeration form
          </div>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                onChange={(e) => setVfName(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                onChange={(e) => setvLName(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="">
              <Form.Label>S/O</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => setSo(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                style={{ width: "300px" }}
                type="email"
                placeholder="Enter email"
                onChange={(e) => setemail(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="">
              <Form.Label style={{ marginLeft: "0px" }}>
                {" "}
                Date OF Birth
              </Form.Label>
              <Form.Control
                style={{ marginLeft: "0px", width: "" }}
                type="date"
                onChange={(e) => setdob(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label style={{ marginLeft: "00px" }}>Age</Form.Label>
              <Form.Control
                style={{ width: "100px", marginLeft: "00px" }}
                // onChange={(e) => setage(e.target.value)}
               placeholder="Enter age"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3"></Row>

          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Address</Form.Label>
            <Form.Control
              placeholder="1234 Main St"
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Group>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Country</Form.Label>
              <Form.Select
                defaultValue="Choose..."
                onChange={(e) => setcountry(e.target.value)}
              >
                <option>Choose...</option>
                {CountryList?.map((item) => {
                  return <option value={item?.isoCode}>{item.name}</option>;
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>State</Form.Label>
              <Form.Select
                defaultValue="Choose..."
                onChange={(e) => setstate(e.target.value)}
              >
                <option>Choose...</option>
                {StateList?.map((item) => {
                  return <option value={item?.isoCode}>{item.name}</option>;
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>City</Form.Label>
              <Form.Select
                defaultValue="Choose..."
                onChange={(e) => setcity(e.target.value)}
              >
                <option>Choose...</option>
                {CityList?.map((item) => {
                  return <option value={item?.name}>{item.name}</option>;
                })}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Dno./Street</Form.Label>
              <Form.Control onChange={(e) => setStreet(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Taluk</Form.Label>
              <Form.Control onChange={(e) => settaluka(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>District</Form.Label>
              <Form.Control onChange={(e) => setdist(e.target.value)} />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Pincode</Form.Label>
              <Form.Control onChange={(e) => setpincode(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control onChange={(e) => setphno(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>WhatsApp Number</Form.Label>
              <Form.Control onChange={(e) => setwhatsapp(e.target.value)} />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Gps Location</Form.Label>
              <Form.Control onChange={(e) => setgpsLoc(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Education</Form.Label>
              <Form.Control onChange={(e) => seteducation(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Occupation</Form.Label>
              <Form.Control onChange={(e) => setoccupation(e.target.value)} />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Addhar Number</Form.Label>
              <Form.Control onChange={(e) => setaadhar(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Pan Number</Form.Label>
              <Form.Control onChange={(e) => setpan(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Reffered By</Form.Label>
              <Form.Select
                defaultValue="Choose..."
                onChange={(e) => setrefferedBy(e.target.value)}
              >
                <option>Choose...</option>
                {volunteerList?.map((item) => {
                  return (
                    <option value={item?._id}>
                      {item._id} {item.vfName} {item.vlName}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Volunteer interest</Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Full Time "
                    name="group1"
                    type="radio"
                    onChange={(e) => setvolunteeringInterest("fulltime")}
                  />{" "}
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="Part Time "
                    name="group1"
                    type="radio"
                    onChange={(e) => setvolunteeringInterest("parttime")}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                id="upload1"
                name="file"
                onChange={(e) => setvPic(e.target.files[0])}
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" id="formGridCheckbox">
            <Form.Check
              type="checkbox"
              onChange={() => setisTermsandcondAcc(!isTermsandcondAcc)}
              label="I Accept T&C of the Sri Vinayaka Charitable Trust® "
            />
          </Form.Group>
          <br></br>
        </Form>
        <Button
          variant="primary"
          // type="submit"
          onClick={(e) => registerVolunteer(e)}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Formv;
