import React, { useEffect } from "react";
import ShareIcon from "@mui/icons-material/Share";
import "./Fundraiser.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import parse from "html-react-parser";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation } from "react-router-dom";
import Sidefund from "./Sidefund";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import Navigation from "../../Navigation";
import Footer from "../Footer/Footer";
import { Container } from "react-bootstrap";
// import Contributenow from "./Contributenow";
import useRazorpay from "react-razorpay";
import axios from "axios";

const Fundraiser = () => {
  const [show, setShow] = useState(false);

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const { state } = useLocation();

  const Razorpay = useRazorpay();

  /* ============================================================================================================== */

  const [fullName, setfullName] = useState("");
  const [phno, setphno] = useState("");
  const [email, setemail] = useState("");
  const [donatetype, setDonatetype] = useState("");
  const [amt, setamt] = useState(0);
  const [remark, setremark] = useState("");
  const [payid, setpayid] = useState("");
  const [expDate, setexpDate] = useState("");
  const [anonymous, setanonymous] = useState(false);
  const [data, setdata] = useState({});
  const [individualOrORG, setindividualOrORG] = useState("");

  /* ============================================================================================================== */

  const donarValidation = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: `/user/userDonationVal`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: {
          fullName: fullName,
          phno: phno,
          email: email,
          donateType: donatetype,
          amt: Number(amt),
          remark: remark,
          expDate: expDate,
          individualOrORG: individualOrORG,
          anonymous: anonymous,
          // volToken: volToken,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          setdata(res.data.data);
          posttransaction();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ============================================================================================================== */

  const addDonar = async () => {
    data.payid = payid;
    try {
      const config = {
        url: `/user/userDonation`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: data,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          // donarList();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ============================================================================================================== */

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const posttransaction = async () => {
    if (!fullName || !phno || !email || !donatetype || !amt) {
      return alert("please fill all the neccessary field");
    }
    try {
      const config = {
        data: {
          key: "rzp_test_5vCRZ6rLM2wGN4",
          amount: Number(amt) * 100,
          currency: "INR",
          name: "Vinayaka Trust",
          description: "Order Amount",
          image: "/Logo-1.jpg",
          email: email,
          handler: function (response) {
            alert(response.razorpay_payment_id);
            setpayid(response.razorpay_payment_id);
          },
          prefill: {
            email: email,
            phno: phno,
          },
        },
      };
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Fail to load");
        return;
      }
      const paymentObject = new Razorpay(config.data);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };

  /* ============================================================================================================== */
  useEffect(() => {
    if (payid) {
      addDonar();
    }
  }, [payid]);

  // let arr = state?.content?.split("</p>");
  return (
    <>
    <div>
      <Header />
      <Navigation />

      <div
        style={{
          display: "flex",
          alignItems: "",
          justifyContent: "center",
        }}
      >
        <div className="fundraiser">
          <div className="container fund-image">
            <div>
              <img
                src={`https://srivinayaka.info/Fundraise/${state?.bannerimg}`}
                style={{ height: "418px", width: "742px" }}
              ></img>
            </div>

            <div
              style={{
                textAlign: "right",
                marginRight: "0px",
                width: "750px",
                height: "85px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                }}
              >
                {/* <Link className="share" to="/">
                  {" "}
                  <ShareIcon />
                  <span
                    style={{
                      padding: "20px",
                    }}
                  >
                    Share this Fundraiser
                  </span>
                </Link> */}
              </div>
            </div>
            <Container fluid className="py-4">
      {/* Title Section */}
      <Row className="text-center mb-4">
        <Col>
          <h1 className="fund-h1">About the Fundraiser</h1>
        </Col>
      </Row>

      {/* Image Section */}
      <Row className="d-flex align-item-center justify-content-center mb-4">
        <Col md={4} sm={12} className="mb-3">
          <img
            src={`https://srivinayaka.info/Fundraise/${state?.img1}`}
            className="img-fluid"
            alt="Fundraiser Image 1"
            style={{ height: "350px", objectFit: "cover" }}
          />
        </Col>
        <Col md={4} sm={12} className="mb-3">
          <img
            src={`https://srivinayaka.info/Fundraise/${state?.img2}`}
            className="img-fluid"
            alt="Fundraiser Image 2"
            style={{ height: "350px", objectFit: "cover" }}
          />
        </Col>
        <Col md={4} sm={12} className="mb-3">
          <img
            src={`https://srivinayaka.info/Fundraise/${state?.img3}`}
            className="img-fluid"
            alt="Fundraiser Image 3"
            style={{ height: "350px", objectFit: "cover" }}
          />
        </Col>
      </Row>

      {/* Content Section */}
      <Row>
        <Col>
          <div className="px-4" style={{ textAlign: "justify" }}>
            <p>{parse(`<div>${state?.content}</div>`)}</p>
          </div>
        </Col>
      </Row>
    </Container>

            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
                marginRight: "0px",
                width: "750px",
                height: "85px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                }}
              >
                {/* <Link className="share" to="/">
                  <span
                    style={{
                      padding: "20px",
                    }}
                  >
                    Read Latest Updates
                  </span>
                </Link> */}
              </div>
            </div>

            <div
              style={{
                marginTop: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "0px",
                width: "750px",
                height: "85px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                }}
              >
                <Link className="share" to="/">
                  <WhatsAppIcon />
                  <span
                    style={{
                      padding: "20px",
                    }}
                  >
                    Share
                  </span>
                </Link>
              </div>
              <div
                style={{
                  padding: "20px",
                }}
              >
                <Link className="share" to="/">
                  <FacebookIcon />
                  <span
                    style={{
                      padding: "20px",
                    }}
                  >
                    Share
                  </span>
                </Link>
              </div>
              <div
                style={{
                  padding: "20px",
                }}
              >
                <Button
                  className="share"
                  variant="primary"
                  onClick={handleShow}
                >
                  Contribute Now
                </Button>
              </div>
            </div>

            {/* <div
              className="doation-methods"
              style={{
                marginTop: "50px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                }}
              >
                <p
                  style={{
                    borderBottom: "solid 2px #6b6b6b",
                    fontSize: "24px ",
                    fontWeight: "550px",
                  }}
                >
                  Other Donation Methods
                </p>
              </div>
              <div
                style={{
                  padding: "0px 40px 20px 40px",
                }}
              >
                <p style={{ fontSize: "17px ", fontWeight: "600px" }}>
                  {" "}
                  Transfer directly to bank account of Fundraiser
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  {" "}
                  <AccountBalanceIcon style={{ fontSize: "200px" }} />
                </div>

                <div style={{ width: "400px" }}>
                  <p style={{ width: "400px" }}>
                    <span style={{ padding: "0px" }}>Virtual A/C No</span>{" "}
                    <span style={{ padding: "0px" }}>:</span>
                    <span style={{ padding: "0px", textAlign: "center" }}>
                      -------------------
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span style={{ padding: "px", width: "250px" }}>
                      Virtual A/C Name
                    </span>{" "}
                    <span style={{ padding: "px" }}>:</span>
                    <span style={{ padding: "px" }}>-------------------</span>
                  </p>

                  <p>
                    {" "}
                    <span style={{ padding: "0px" }}>A/C Type</span>{" "}
                    <span style={{ padding: "0px" }}>:</span>
                    <span style={{ padding: "0px" }}>-------------------</span>
                  </p>
                  <p>
                    {" "}
                    <span style={{ padding: "0px" }}> IFSC </span>{" "}
                    <span style={{ padding: "0px" }}>:</span>
                    <span style={{ padding: "0px" }}>-------------------</span>
                  </p>
                </div>
              </div>
            </div> */}
{/* 
            <Link
              className=""
              to="/"
              style={{
                textDecoration: "none",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  padding: "10px",
                  background: "#111",
                  color: "#fff",
                  width: "750px",
                }}
              >
                Claim your donation acknowledgement now! For payment done via
                Bank Transfer
              </div>
            </Link> */}
{/* 
            <div
              style={{
                padding: "25px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                }}
              >
                <p
                  style={{
                    borderBottom: "solid 2px #6b6b6b",
                    fontSize: "24px ",
                    fontWeight: "550px",
                  }}
                >
                  Comments
                </p>
              </div>
              <Form style={{ padding: "10px" }}>
                <FloatingLabel controlId="floatingTextarea2" label="Comments">
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                  />
                </FloatingLabel>
                <br></br>

                <div className="submit">
                  <Button
                    variant="primary"
                    type="submit"
                    className="submit mt-1 "
                  >
                    Submit
                  </Button>
                </div>
              </Form>{" "}
            </div> */}
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Donate</Modal.Title>
            </Modal.Header>{" "}
            <Modal.Body>
              <div
                style={{
                  padding: "20px auto",
                }}
              >
                <Form.Floating className="mb-3">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div>
                      <Button
                        variant="outline-success"
                        style={{
                          fontSize: "24px",

                          marginTop: "20px",
                          borderColor: "",
                          fontWeight: "bold",

                          width: "120px",
                          height: "50px",
                        }}
                        onClick={(e) => setamt(25)}
                      >
                        {" "}
                        ₹25
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outline-success"
                        style={{
                          fontSize: "24px",
                          marginLeft: "30px",
                          marginTop: "20px",
                          borderColor: "",
                          fontWeight: "bold",

                          width: "120px",
                          height: "50px",
                        }}
                        onClick={(e) => setamt(50)}
                      >
                        ₹50
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outline-success"
                        style={{
                          fontSize: "24px",
                          marginLeft: "30px",
                          marginTop: "20px",
                          borderColor: "",
                          fontWeight: "bold",

                          width: "120px",
                          height: "50px",
                        }}
                        onClick={(e) => setamt(70)}
                      >
                        {" "}
                        ₹70
                      </Button>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      marginLeft: "-30px",
                    }}
                  >
                    <div>
                      <Button
                        variant="outline-success"
                        style={{
                          fontSize: "24px",
                          marginLeft: "30px",
                          marginTop: "20px",
                          borderColor: "",
                          fontWeight: "bold",

                          width: "120px",
                          height: "50px",
                        }}
                        onClick={(e) => setamt(100)}
                      >
                        {" "}
                        ₹100
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outline-success"
                        style={{
                          fontSize: "24px",
                          marginLeft: "30px",
                          marginTop: "20px",
                          borderColor: "",
                          fontWeight: "bold",

                          width: "120px",
                          height: "50px",
                        }}
                        onClick={(e) => setamt(200)}
                      >
                        ₹200
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outline-success"
                        style={{
                          fontSize: "24px",
                          marginLeft: "30px",
                          marginTop: "20px",
                          borderColor: "",
                          fontWeight: "bold",

                          width: "120px",
                          height: "50px",
                        }}
                        onClick={(e) => setamt(500)}
                      >
                        {" "}
                        ₹500
                      </Button>
                    </div>
                  </div>
                  <br></br>

                  <div style={{ padding: "5px 10px 5px 10px " }}>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="inputGroup-sizing-default">
                        Amount
                      </InputGroup.Text>
                      <Form.Control
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        value={amt}
                        onChange={(e) => setamt(e.target.value)}
                        required
                      />
                    </InputGroup>
                  </div>

                  <Row>
                    <Col>
                      <Form.Label style={{ marginLeft: "10px" }}>
                        Donation Freequency
                      </Form.Label>

                      <div
                        style={{
                          padding: "0px 10px 5px 10px ",
                          marginTop: "-2px",
                        }}
                      >
                        <Form.Select
                          aria-label="Default select example"
                          style={{ height: "37px", padding: "4px" }}
                          onChange={(e) => setDonatetype(e.target.value)}
                        >
                          <option value="">Select one</option>
                          <option value="onetime">One-time</option>
                          <option value="monthly">Monthly</option>
                          <option value="quaterly">Quaterly</option>
                          <option value="yearly">Annually</option>
                        </Form.Select>
                      </div>
                    </Col>
                    {donatetype == "monthly" ||
                    donatetype == "quaterly" ||
                    donatetype == "yearly" ? (
                      <Col>
                        <Form.Label style={{ marginLeft: "0px" }}>
                          Expiry Date
                        </Form.Label>
                        <Form.Control
                          style={{ marginLeft: "0px", width: "" }}
                          type="date"
                          onChange={(e) => setexpDate(e.target.value)}
                        />
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>

                  <p className="fs-5 text-center mt-4">
                    Are you representing an Individual or Organization?*
                  </p>

                  <div className="row mt-3">
                    <div class=" col form-check  text-center">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        // checked
                        onChange={(e) => setindividualOrORG("individual")}
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Individual
                      </label>
                    </div>
                    <div class=" col form-check  text-center">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        // checked
                        onChange={(e) => setindividualOrORG("organization")}
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Organization
                      </label>
                    </div>
                  </div>
                  <div className="">
                    <div className="row mt-4">
                      <div class="mb-3 col">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Name / Organization Name
                        </label>
                        <input
                          type="text"
                          class="form-c border"
                          id="exampleFormControlInput1"
                          placeholder="Name"
                          onChange={(e) => setfullName(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div class="mb-3 col">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Email / Organization Mail
                        </label>
                        <input
                          type="email"
                          class="form-c border"
                          id="exampleFormControlInput1"
                          placeholder="name@example.com"
                          onChange={(e) => setemail(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div class="mb-3 col">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Phone no. / Organization Phone no.
                        </label>
                        <input
                          type="number"
                          class="form-c border"
                          id="exampleFormControlInput1"
                          placeholder="9876543210"
                          onChange={(e) => setphno(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "5px 10px 5px 10px ",
                      fontSize: "16px",
                      color: "#111",
                    }}
                  >
                    Message
                  </div>

                  <div style={{ padding: "5px 10px 5px 10px " }}>
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Message"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: "100px" }}
                        onChange={(e) => setremark(e.target.value)}
                      />
                    </FloatingLabel>
                  </div>

                  {/* <div style={{ padding: "5px 10px 5px 10px " }}>
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          style={{
                            marginTop: "10px",
                          }}
                          label="Anonymous"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          onChange={() => setanonymous(!anonymous)}
                        />
                      </div>
                    ))}
                  </div> */}
                </Form.Floating>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <div>
                <Button
                  variant="outline-success"
                  onClick={(e) => donarValidation(e)}
                >
                  {" "}
                  Donate
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
        {/* ============================================= */}

        <Sidefund />
      </div>
    </div>
          <Footer/>
          </>
  );
};

export default Fundraiser;
