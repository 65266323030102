import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "./gallery.css";

const Gallery = () => {
  const [galleries, setGalleries] = useState([]);

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  const getImages = async () => {
    try {
      const res = await axios.get("https://srivinayaka.info/api/admin/getgallery");
      if (res.status === 200) {
        setGalleries(res.data.galleryList);
      }
    } catch (error) {
      console.error(error);
      setGalleries([]);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <>
      <div>
        <Container>
          <Row>
            {/* Display only up to 6 images */}
            {galleries.slice(0, 6).map((item, index) => (
              <Col xs={12} sm={6} md={4} className="mb-4" key={index}>
                <Image
                  src={`https://srivinayaka.info/Gallery/${item?.galleryimg}`}
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                  }}
                  rounded
                />
              </Col>
            ))}
          </Row>

        </Container>
      </div>
    </>
  );
};

export default Gallery;
