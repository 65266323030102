import React, { useEffect, useState } from "react";
import "./aboutus.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
import parse from "html-react-parser";
const Aboutus = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  
  const [Aboutuslist, setAboutusList] = useState([]);
  const AboutusList = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getAboutusList")
        .then((res) => {
          if (res.status === 200) {
            // console.log(res);
            setAboutusList(res.data.AboutusList);
          }
        });
    } catch (error) {
      console.log(error);
      setAboutusList([]);
    }
  };

  useEffect(() => {
    AboutusList();
  }, []);
  return (
    <Container fluid style={{ padding: "0px" }}>
      <div
        className
        style={{
          fontSize: "28px",
          color: "#DA4453",
          fontWeight: "500",
          textAlign: "center",
          paddingTop: "30px",
          paddingBottom: "-30px",
        }}
      >
        About Us
      </div>

      <Container style={{ padding: "0px", marginTop: "0px" }}>
        <ul style={{ textAlign: "center", margin: "0px" }}>
          <li>
            <img
              src="./implogoc.jpeg"
              style={{
                height: "75px",
                border: "solid 2px white ",
                marginTop: "px",
                borderRadius: "50%",
              }}
              alt=""
            ></img>
          </li>
          <li
            id=""
            style={{
              color: "#DA4453",
              textTransform: "uppercase",
              fontSize: "22px",
              padding: "10px",
            }}
          >
            Sri Vinayaka Charitable Trust
          </li>
          <li
            id=""
            style={{
              textAlign: "justify",
              lineHeight: "32px",
              color: "#6B6b6b",
              padding: "10px 40px 10px 40px",
            }}
          >
            {parse(`<div>${Aboutuslist[0]?.content?.substr(0, 400)}...</div>`)}
          </li>
          <li id="btn-k">
            <Link to="/aboutpage"  style={{color:'red',border:'1px solid red',padding:'6px 20px',borderRadius:'5px'}}>
              {" "}
              KNOW MORE 
            </Link>
          </li>
        </ul>
      </Container>
    </Container>
  );
};

export default Aboutus;
