import React, { useEffect, useState } from "react";
import "./contactus.css";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import EmailIcon from "@mui/icons-material/Email";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import HeadBanner from "./HeadBanner";
import { Button } from "react-bootstrap";
import Navigation from "../../Navigation";
import axios from "axios";
const Contactus = () => {
  /* ======================================================================================================== */
  const [ContactDetailss, setContactDetailss] = useState([]);
  const getContactusDetails = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getContactDetails")
        .then((res) => {
          if (res.status === 200) {
            setContactDetailss(res.data.contactusList);
          }
        });
    } catch (error) {
      console.log(error);
      setContactDetailss([]);
    }
  };

  /* ======================================================================================================== */

  const [fullName, setfullName] = useState("");
  const [email, setemail] = useState("");
  const [phno, setphno] = useState();
  const [msg, setmsg] = useState("");
  const requestContact = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: `/user/contactRequest`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: {
          fullName: fullName,
          email: email,
          phno: phno,
          msg: msg,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          setfullName("");
          setemail("");
          setphno("");
          setmsg("");
          alert(res.data.success);
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ======================================================================================================== */

  useEffect(() => {
    getContactusDetails();
  }, []);

  return (
    <div>
      <Header></Header>
      <Navigation />
      <img
        src={`https://srivinayaka.info/Contactus/${ContactDetailss[0]?.bannerimg}`}
        style={{
          width: "100%",
          height: "300px",
        }}
      ></img>
      <div style={{ background: "#F4F6F8" }}>
        {" "}
        <div className="container" style={{ background: "#F4F6F8" }}>
          <div
            className
            style={{
              fontSize: "28px",
              color: "#DA4453",
              fontWeight: "500",
              textAlign: "center",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            Contact Us
          </div>
          <br></br>
          <div className="container my-4" style={{backgroundColor:'#2F598F'}}>
  <div className="row d-flex justify-content-center align-item-center">
    {/* Address Section */}
    <div className="col-md-4">
      <div className="card text-center h-100 shadow">
        <div className="card-body">
          <AddBusinessIcon sx={{ fontSize: 40 }} className="mb-3" />
          <h5 className="card-title">Address</h5>
          <p className="card-text">{ContactDetailss[0]?.fulladdress}</p>
        </div>
      </div>
    </div>

    {/* Call Section */}
    <div className="col-md-4">
      <div className="card text-center h-100 shadow">
        <div className="card-body">
          <AddIcCallIcon sx={{ fontSize: 40 }} className="mb-3" />
          <h5 className="card-title">Call Us At</h5>
          <p className="card-text">Contact Number</p>
          <p className="card-text">0{ContactDetailss[0]?.phno}</p>
        </div>
      </div>
    </div>

    {/* Email Section */}
    <div className="col-md-4">
      <div className="card text-center h-100 shadow">
        <div className="card-body">
          <EmailIcon sx={{ fontSize: 40 }} className="mb-3" />
          <h5 className="card-title">Email</h5>
          <p className="card-text">{ContactDetailss[0]?.email}</p>
        </div>
      </div>
    </div>
  </div>
</div>



          <div
            className="text-header"
            style={{ color: "#DA4453", marginTop: "100px" }}
          >
            Please feel free to Contact us{" "}
          </div>

<div style={{backgroundColor:'#2F5876'}}  className="container">
          <div
            className="container contact-form"
            style={{ width: "550px", margin: "30px auto" }}
          >
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="floatingInput"
                value={fullName}
                placeholder="name"
                onChange={(e) => setfullName(e.target.value)}
              />
              <label for="floatingInput">Name</label>
            </div>

            <div class="form-floating mb-3">
              <input
                type="email"
                class="form-control"
                id="floatingInput"
                value={email}
                placeholder="name@example.com"
                onChange={(e) => setemail(e.target.value)}
              />
              <label for="floatingInput">Email address</label>
            </div>
            <div class="form-floating mb-3">
              <input
                type="tel"
                class="form-control"
                id="floatingNumber"
                value={phno}
                placeholder="MobileNumber"
                onChange={(e) => setphno(e.target.value)}
              />
              <label for="floatingNumber">Mobile no</label>
            </div>
            <div class="form-floating mb-3">
              <textarea
                class="form-control"
                placeholder="Leave a comment here"
                id="floatingTextarea2"
                value={msg}
                style={{}}
                onChange={(e) => setmsg(e.target.value)}
              ></textarea>
              <label for="floatingTextarea2">Message</label>
            </div>

            <div class="col-12 " style={{ textAlign: "center" }}>
              <Button
                variant="outline-danger"
                class="btn btn-primary btn-k"
                type="submit"
                onClick={(e) => requestContact(e)}
              >
                Submit
              </Button>
            </div>
          </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contactus;
