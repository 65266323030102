import React, { useEffect, useState } from "react";
import Slider from "../Slider";
import Aboutus from "../Aboutus/Aboutus";
import Services from "../Services/Services";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Image from "react-bootstrap/Image";
import SavingsIcon from "@mui/icons-material/Savings";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import Footer from "../Footer/Footer";
import { Button, Col, Container, FloatingLabel, Form, InputGroup, Modal, Row } from "react-bootstrap";

import Activefundraiser from "../ActiveFundraiser/Activefundraiser";
// import Fundraiser from '../Startafundraiser/Fundraiser'
import Gallery from "../Gallery/Gallery";
import News from "../News/News";
import Navigation from "../../Navigation";
import "./body.css";
import axios from "axios";
import useRazorpay from "react-razorpay";


const Body = () => {

  const Razorpay = useRazorpay();

  // const [info, setInfo] = useState({});
  // const getUser = async (id)=>{
  //   try{
  //     const res = await axios.get(`https://srivinayaka.info/api/user/getuserByid/${id}`);
  //     if(res.status === 200){
  //       setInfo(res.data.dHistory);
  //     }
  //   }catch(error){
  //     console.log(error);
  //   }
  // }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const [fullName, setfullName] = useState("");
    const [phno, setphno] = useState("");
    const [email, setemail] = useState("");
    const [donatetype, setDonatetype] = useState("");
    const [amt, setamt] = useState(0);
    const [remark, setremark] = useState("");
    const [payid, setpayid] = useState("");
    const [expDate, setexpDate] = useState("");
    const [anonymous, setanonymous] = useState(false);
    const [data, setdata] = useState({});
    const [individualOrORG, setindividualOrORG] = useState("");

  
    const donarValidation = async (e) => {
      e.preventDefault();
  
      try {
        const config = {
          url: `/user/userDonationVal`,
          method: "post",
          baseURL: "https://srivinayaka.info/api",
          header: { "content-type": "application/x-www-form-urlencoded" },
          data: {
            fullName: fullName,
            phno: phno,
            email: email,
            donateType: donatetype,
            amt: Number(amt),
            remark: remark,
            expDate: expDate,
            individualOrORG: individualOrORG,
            anonymous: anonymous,
            // volToken: volToken,
          },
          json: true,
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            setdata(res.data.data);
            posttransaction();
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    };
  
    /* ============================================================================================================== */
  
    const addDonar = async () => {
      data.payid = payid;
      try {
        const config = {
          url: `/user/userDonation`,
          method: "post",
          baseURL: "https://srivinayaka.info/api",
          header: { "content-type": "application/x-www-form-urlencoded" },
          data: data,
          json: true,
        };
        await axios(config).then((res) => {
          if (res.status === 201) {
            alert(res.data.success);
            // donarList();
            handleClose();
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    };

    const loadScript = (src) => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.error = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    };
  
    const posttransaction = async () => {
      if (!donatetype || !amt) {
        return alert("please fill all the neccessary field");
      }
      try {
        const config = {
          data: {
            key: "rzp_test_5vCRZ6rLM2wGN4",
            amount: Number(amt) * 100,
            currency: "INR",
            name: "Vinayaka Trust",
            description: "Order Amount",
            image: "/Logo-1.jpg",
            email: email,
            handler: function (response) {
              alert(response.razorpay_payment_id);
              setpayid(response.razorpay_payment_id);
            },
            prefill: {
              email: email,
              phno: phno,
            },
          },
        };
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
          alert("Fail to load");
          return;
        }
        const paymentObject = new Razorpay(config.data);
        paymentObject.open();
      } catch (error) {
        console.log(error);
      }
    };
  // useEffect(()=>{
  //   const userdetails = JSON.parse(localStorage.getItem('encodeUserInfo'));
  //   if(userdetails){
  //     getUser(userdetails?.email);
  //   }
  // })
  return (
    <>
    <div>
      <Header></Header>
      <Navigation></Navigation>
      <Slider></Slider>
      <Aboutus></Aboutus>
      <Services></Services>
      <Activefundraiser></Activefundraiser>
      <Container fluid style={{ margin: "0px", padding: "0px" }}>
        <br></br>
        <div
          className
          style={{
            fontSize: "28px",
            color: "#DA4453",
            fontWeight: "500",
            textAlign: "center",
            paddingTop: "30px",
            paddingBottom: "-30px",
          }}
        >
          Why are people choosing medical crowdfunding?
        </div>
        <br></br>

        <Row style={{ padding: "0px", margin: "0px" }}>
          <Col
            md={{ span: 5 }}
            style={{ padding: "0px", margin: "auto", textAlign: "center" }}
          >
            <div style={{ padding: "0px" }}>
              {" "}
              <SavingsIcon fontSize="large" />
              <div
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  color: "#444444",
                  fontSize: "22px",
                  fontWeight: "550",
                }}
              >
                {" "}
                High Cost Of Treatment
              </div>
              <div
                style={{
                  textAlign: "center",
                  lineHeight: "32px",

                  color: "#6B6b6b",
                  textAlign: "justify",
                }}
              >
                Medical emergencies come without a warning. At times, health
                insurance and savings are not enough to cover the expensive
                bill. Crowdfunding helps you reach your goal by getting small
                donations from a large group of people.
              </div>
            </div>
          </Col>

          <Col
            md={{ span: 5 }}
            style={{ textAlign: "center", padding: "0px", margin: "auto" }}
          >
            <br></br>
            <div>
              {" "}
              <MoreTimeIcon fontSize="large" />
              <div
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  color: "#444444",
                  fontSize: "22px",
                  fontWeight: "550",
                }}
              >
                {" "}
                Emergencies Demand A Prompt Response
              </div>
              <div
                style={{
                  textAlign: "center",

                  color: "#6B6b6b",
                  lineHeight: "32px",
                  textAlign: "justify",
                }}
              >
                When your loved ones are diagnosed with life threatening
                diseases, it’s overwhelming emotionally as well as financially.
                Most medical emergencies require the treatment to start at the
                earliest. By giving you a platform to share your emergency,
                online crowdfunding helps you gather funds quickly.
              </div>
            </div>
          </Col>
        </Row>

        <br></br>
      </Container>
      {/* <Fundraiser></Fundraiser> */}
      <br></br> <br></br> <br></br>
      <div
        className
        style={{
          fontSize: "28px",
          color: "#DA4453",
          fontWeight: "500",
          textAlign: "center",
          paddingTop: "30px",
          paddingBottom: "-30px",
        }}
      >
        Gallery
      </div>
      <Gallery></Gallery>
    
       

     
    </div>
    <div className="d-flex justify-content-center">
     <Link to="/gallery" variant="outline-danger" style={{color:'red',border:'1px solid red',padding:'6px 20px',borderRadius:'5px'}}>
       MORE
   </Link>
   </div>
    {/* <News></News> */}
    <div className="textone" style={{ width:'100%' , backgroundColor:"#0199FF",color:'white'}}>
                <div style={{fontSize:'25px'}}>
                Join the Mission – Give for a Better Tomorrow
                </div>
                <div>
                  <Button style={{color:'white'}} variant="outline-primary" onClick={handleShow}>
                    DONATE NOW
                  </Button>
                  </div>
                  
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Donate</Modal.Title>
              </Modal.Header>{" "}
              <Modal.Body>
                <div
                  style={{
                    padding: "20px auto",
                  }}
                >
                  <Form.Floating className="mb-3">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div>
                        <Button
                          variant="outline-success"
                          style={{
                            fontSize: "24px",

                            marginTop: "20px",
                            borderColor: "",
                            fontWeight: "bold",

                            width: "120px",
                            height: "50px",
                          }}
                          onClick={(e) => setamt(25)}
                        >
                          {" "}
                          ₹25
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="outline-success"
                          style={{
                            fontSize: "24px",
                            marginLeft: "30px",
                            marginTop: "20px",
                            borderColor: "",
                            fontWeight: "bold",

                            width: "120px",
                            height: "50px",
                          }}
                          onClick={(e) => setamt(50)}
                        >
                          ₹50
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="outline-success"
                          style={{
                            fontSize: "24px",
                            marginLeft: "30px",
                            marginTop: "20px",
                            borderColor: "",
                            fontWeight: "bold",

                            width: "120px",
                            height: "50px",
                          }}
                          onClick={(e) => setamt(70)}
                        >
                          {" "}
                          ₹70
                        </Button>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        marginLeft: "-30px",
                      }}
                    >
                      <div>
                        <Button
                          variant="outline-success"
                          style={{
                            fontSize: "24px",
                            marginLeft: "30px",
                            marginTop: "20px",
                            borderColor: "",
                            fontWeight: "bold",

                            width: "120px",
                            height: "50px",
                          }}
                          onClick={(e) => setamt(100)}
                        >
                          {" "}
                          ₹100
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="outline-success"
                          style={{
                            fontSize: "24px",
                            marginLeft: "30px",
                            marginTop: "20px",
                            borderColor: "",
                            fontWeight: "bold",

                            width: "120px",
                            height: "50px",
                          }}
                          onClick={(e) => setamt(200)}
                        >
                          ₹200
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="outline-success"
                          style={{
                            fontSize: "24px",
                            marginLeft: "30px",
                            marginTop: "20px",
                            borderColor: "",
                            fontWeight: "bold",

                            width: "120px",
                            height: "50px",
                          }}
                          onClick={(e) => setamt(500)}
                        >
                          {" "}
                          ₹500
                        </Button>
                      </div>
                    </div>
                    <br></br>

                    <div style={{ padding: "5px 10px 5px 10px " }}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-default">
                          Amount
                        </InputGroup.Text>
                        <Form.Control
                          aria-label="Default"
                          aria-describedby="inputGroup-sizing-default"
                          value={amt}
                          onChange={(e) => setamt(e.target.value)}
                          required
                        />
                      </InputGroup>
                    </div>

                    <Row>
                      <Col>
                        <Form.Label style={{ marginLeft: "10px" }}>
                          Donation Freequency
                        </Form.Label>

                        <div
                          style={{
                            padding: "0px 10px 5px 10px ",
                            marginTop: "-2px",
                          }}
                        >
                          <Form.Select
                            aria-label="Default select example"
                            style={{ height: "37px", padding: "4px" }}
                            onChange={(e) => setDonatetype(e.target.value)}
                          >
                            <option value="">Select one</option>
                            <option value="onetime">One-time</option>
                            <option value="monthly">Monthly</option>
                            <option value="quaterly">Quaterly</option>
                            <option value="yearly">Annually</option>
                          </Form.Select>
                        </div>
                      </Col>
                      {donatetype == "monthly" ||
                      donatetype == "quaterly" ||
                      donatetype == "yearly" ? (
                        <Col>
                          <Form.Label style={{ marginLeft: "0px" }}>
                            Expiry Date
                          </Form.Label>
                          <Form.Control
                            style={{ marginLeft: "0px", width: "" }}
                            type="date"
                            onChange={(e) => setexpDate(e.target.value)}
                          />
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
{/* 
                    <p className="fs-5 text-center mt-4">
                      Are you representing an Individual or Organization?*
                    </p>

                    <div className="row mt-3">
                      <div class=" col form-check  text-center">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          // checked
                          onChange={(e) => setindividualOrORG("individual")}
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Individual
                        </label>
                      </div>
                      <div class=" col form-check  text-center">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          // checked
                          onChange={(e) => setindividualOrORG("organization")}
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Organization
                        </label>
                      </div>
                    </div> */}
                    <div className="">
                      <div className="row mt-4">
                        <div class="mb-3 col">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Name / Organization Name
                          </label>
                          <input
                            type="text"
                            class="form-c border"
                            id="exampleFormControlInput1"
                            placeholder="Name"
                            onChange={(e) => setfullName(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div class="mb-3 col">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Email / Organization Mail
                          </label>
                          <input
                            type="email"
                            class="form-c border"
                            id="exampleFormControlInput1"
                            placeholder="name@example.com"
                            onChange={(e) => setemail(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div class="mb-3 col">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Phone no. / Organization Phone no.
                          </label>
                          <input
                            type="number"
                            class="form-c border"
                            id="exampleFormControlInput1"
                            placeholder="9876543210"
                            onChange={(e) => setphno(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "5px 10px 5px 10px ",
                        fontSize: "16px",
                        color: "#111",
                      }}
                    >
                      Message
                    </div>

                    <div style={{ padding: "5px 10px 5px 10px " }}>
                      <FloatingLabel
                        controlId="floatingTextarea2"
                        label="Message"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Leave a comment here"
                          style={{ height: "100px" }}
                          onChange={(e) => setremark(e.target.value)}
                        />
                      </FloatingLabel>
                    </div>
{/* 
                    <div style={{ padding: "5px 10px 5px 10px " }}>
                      {["checkbox"].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <Form.Check
                            inline
                            style={{
                              marginTop: "10px",
                            }}
                            label="Anonymous"
                            name="group1"
                            type={type}
                            id={`inline-${type}-1`}
                            onChange={() => setanonymous(!anonymous)}
                          />
                        </div>
                      ))}
                    </div> */}
                  </Form.Floating>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                
                  <Button
                    variant="outline-success"
                    onClick={(e) => donarValidation(e)}
                  >
                    {" "}
                    Donate
                  </Button>
                
              </Modal.Footer>
            </Modal>
                  </div>
      <Footer />
   </>
  );
};

export default Body;
