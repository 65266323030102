import React from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const Header = () => {
  return (
    <Container fluid className="p-0">
      <ul className="header-list flex-wrap">
        <li>
          <div className="sri">
            Welcome to Sri Vinayaka Charitable Trust,
          </div>
        </li>

        <li className="d-flex justify-content-center align-items-center flex-wrap">
          <div className="sri mx-3">
            srivinayakacharitabletrust@gmail.com
          </div>
          <div className="sri mx-3">08095719080</div>
        </li>

        <li>
          <Link to="/volunteerlogin">
            <div className="sri text-nowrap">Volunteer's Login</div>
          </Link>
        </li>
{/* 
        <li>
          <div className="login text-nowrap">
            <Link to="/login">Login</Link> | <Link to="/register">Signup</Link>
          </div>
        </li> */}
      </ul>
    </Container>
  );
};

export default Header;
