import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Modal, Table } from "react-bootstrap";
import parse from "html-react-parser";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function ApprovedFund() {

  const formdata = new FormData();
  // show content
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // edit
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // delete
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setcontent(data);
  };

  const [cause, setcause] = useState("");
  const [fullName, setfullName] = useState("");
  const [email, setemail] = useState("");
  const [phno, setphno] = useState("");
  const [img1, setimg1] = useState("");
  const [img2, setimg2] = useState("");
  const [img3, setimg3] = useState("");
  const [bannerimg, setbannerimg] = useState("");
  const [content, setcontent] = useState("");
  const [goalAmt, setgoalAmt] = useState("");
  const [raisedAmt, setraisedAmt] = useState("");
  const [req, setreq] = useState({});

  const [apprFunds, setapprFunds] = useState([]);
  const [Data, setData] = useState({});

  const getApprFund = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getapprFund")
        .then((res) => { 
          console.log("GetAmount",res);
          
          if (res.status === 200) {

          
            setapprFunds(res.data.apprFund);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const BlockstatusFund = async (e, id, state) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/blockstatusFund/${id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
        data: {
          state: state,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getApprFund();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editApprFund = async (e, id) => {

    e.preventDefault();
  
    formdata.set("fullName", fullName);
    formdata.set("email", email);
    formdata.set("phno", phno);
    formdata.set("cause", cause);
    formdata.set("bannerimg", bannerimg);
    formdata.set("img1", img1);
    formdata.set("img2", img2);
    formdata.set("img3", img3);
    formdata.set("content", content);
    formdata.set("goalAmt", goalAmt);
    formdata.set("raisedAmt", raisedAmt);
  
    try {
      const config = {
        url: `/admin/editapproveFundraise/${id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      const response = await axios(config);
      console.log("Edit Response",response);
      
      if (response.status === 200) {
        alert(response.data.success);
  
        // Update the apprFunds state with the edited item
        setapprFunds((prevFunds) =>
          prevFunds.map((fund) =>
            fund._id === id
              ? { ...fund, raisedAmt: raisedAmt, ...response.data.updatedFields }
              : fund
          )
        );
  
        getApprFund(); // optional if the backend updates are accurate
        handleClose1();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  

  const deleteApprFund = async (e, id) => {
    e.preventDefault();

    try {
      const config = {
        url: `/admin/deleteapproveFundraise/${id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getApprFund();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getApprFund();
  }, []);

  return (
    <div>
      <div
        className="main-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>Approved Fund</h4>  
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Full name</th>
              <th>Email</th>
              <th>Phone no.</th>
              <th>Cause</th>
              <th>Banner Image</th>
              <th>Image1</th>
              <th>Image2</th>
              <th>Image3</th>
              <th>content</th>
              <th>Goal</th>
              <th>Raised</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {apprFunds.map((item, i) => {
              return (
                <tr key={item._id}>
                  <td> {++i}. </td>
                  <td> {item?.fullName}</td>
                  <td> {item?.email}</td>
                  <td> {item?.phno}</td>
                  <td> {item?.cause}</td>
                  <td>
                    <img
                      src={`https://srivinayaka.info/Fundraise/${item?.bannerimg}`}
                      alt=""
                      style={{ width: "70px" }}
                    />
                  </td>
                  <td>
                    {" "}
                    <img
                      src={`https://srivinayaka.info/Fundraise/${item?.img1}`}
                      alt=""
                      style={{ width: "70px" }}
                    />
                  </td>
                  <td>
                    {" "}
                    <img
                      src={`https://srivinayaka.info/Fundraise/${item?.img2}`}
                      alt=""
                      style={{ width: "70px" }}
                    />
                  </td>
                  <td>
                    {" "}
                    <img
                      src={`https://srivinayaka.info/Fundraise/${item?.img3}`}
                      alt=""
                      style={{ width: "70px" }}
                    />
                  </td>
                  <td>
                    <AiOutlineEye
                      onClick={() => {
                        setData(item);
                        handleShow();
                      }}
                    />
                  </td>
                  <td>{item?.goalAmt}₹</td>
                  <td>{item.raisedAmt}₹</td>
                  <td>
                    <AiOutlineEdit
                      style={{ color: "blue", margin: "10px" }}
                      onClick={() => {
                        setreq(item);
                        handleShow1();
                      }}
                    />
                    <AiOutlineDelete
                      style={{ color: "red", margin: "10px" }}
                      onClick={() => {
                        setreq(item);
                        handleShow2();
                      }}
                    />
                    {item?.isBlock ? (
                      <Button
                        onClick={(e) => {
                          BlockstatusFund(e, item?._id, false);
                        }}
                      >
                        Unblock
                      </Button>
                    ) : (
                      <Button
                        onClick={(e) => {
                          BlockstatusFund(e, item?._id, true);
                        }}
                      >
                        Block
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {/* show content modal - starts */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Show content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{parse(`<div>${Data?.content}</div>`)}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* show content modal - ends */}

      {/* edit details modal - starts */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder={req?.fullName}
                onChange={(e) => setfullName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder={req?.email}
                onChange={(e) => setemail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Phone no.</Form.Label>
              <Form.Control
                type="number"
                placeholder={req?.phno}
                onChange={(e) => setphno(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Cause</Form.Label>
              <Form.Control
                type="text"
                placeholder={req?.cause}
                onChange={(e) => setcause(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label htmlFor="upload1">Banner Image</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                accept="image/*"
                onChange={(e) => setbannerimg(e.target.files[0])}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label htmlFor="upload2">Image1</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                accept="image/*"
                onChange={(e) => setimg1(e.target.files[0])}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label htmlFor="upload3">Image2</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                accept="image/*"
                onChange={(e) => setimg2(e.target.files[0])}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label htmlFor="upload4">Image3</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                accept="image/*"
                onChange={(e) => setimg3(e.target.files[0])}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Content</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={req?.content}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Goal Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder={req?.goalAmt}
                onChange={(e) => setgoalAmt(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Raised Amount</Form.Label>
              <Form.Control
                type="number"
                name="raisedAmt"
                value={raisedAmt}
                placeholder={req?.raisedAmt}
                onChange={(e) => setraisedAmt(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => editApprFund(e, req?._id)}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit details modal - ends */}

      {/* delete details modal - starts */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Delete details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the Fund Details?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={(e) => deleteApprFund(e, req?._id)}
          >
            delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete details modal - ends */}
    </div>
  );
}

export default ApprovedFund;
