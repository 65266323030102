import React, { useEffect } from "react";
import "./activefundraiser.css";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

import ReactElasticCarousel from "react-elastic-carousel";
import axios from "axios";
const Activefundraiser = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const now = 60;

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 4, itemsToScroll: 2 },
    { width: 760, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];

  const nextpage = (val) => {
    navigate("/fundraiser", { state: val });
  };

  const [email, setemail] = useState("");
  const [phno, setphno] = useState();
  const [fullName, setfullName] = useState("");
  const [cause, setcause] = useState("");

  const obj = {
    email: email,
    phno: phno,
    fullName: fullName,
    cause: cause,
  };
  const reqFundraise = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/user/reqfundraise`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          setcause("");
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const [CauseList, setCauseList] = useState([]);
  const getDiseaseList = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getDiseaseList")
        .then((res) => {
          if (res.status === 200) {
            setCauseList(res.data.diseaseList);
          }
        });
    } catch (error) {
      console.log(error);
      setCauseList([]);
    }
  };

  const [FundList, setFundList] = useState([]);
  const getFundraiserList = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getapprFund")
        .then((res) => {
          if (res.status === 200) {
            setFundList(res.data.apprFund);
          }
        });
    } catch (error) {
      console.log(error);
      setFundList([]);
    }
  };

  useEffect(() => {
    getDiseaseList();
    getFundraiserList();
  }, []);

  return (
    <Container fluid style={{ background: "#F3FAFD", pddding: "0px" }}>
      <div
        className
        style={{
          fontSize: "28px",
          color: "#DA4453",
          fontWeight: "500",
          textAlign: "center",
          paddingTop: "30px",
          paddingBottom: "-30px",
        }}
      >
        Active Fundraiser's
      </div>{" "}
      <div className="activefund">
  <ReactElasticCarousel breakPoints={breakPoints}>
    {FundList?.map((item) => {
      return (
        <Card style={{ display: "flex", flexDirection: "row",maxWidth:'500px',height:'270px' }}>
          <Card.Img
            variant="top"
            src={`https://srivinayaka.info/Fundraise/${item?.bannerimg}`}
            style={{ height: "100%", width: "40%", objectFit: "cover" }}
          />
          <Card.Body style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingLeft: "10px" }}>
            <Card.Title
              style={{
                color: "#111",
                fontWeight: "500",
                fontSize: "14px",
                marginBottom: "10px",
              }}
            >
              {` We Need Urgent Support For ${item?.fullName}'s ${item?.cause}`}
            </Card.Title>
            <Card.Text
              style={{
                fontSize: "18px",
                color: "#3c4d6b",
                marginBottom: "10px",
              }}
            >
              {/* ₹{item?.raisedAmt} */}
            </Card.Text>
            <Card.Text
              style={{
                fontSize: "14px",
                color: "#3c4d6b",
                marginBottom: "10px",
              }}
            >
              Goal of ₹{item?.goalAmt}
            </Card.Text>
            <ProgressBar
      now={100} 
      variant="success"  
      style={{ height: "20px", marginBottom: "15px" }}
      label={`100%`} 
    />
    
            <div style={{ textAlign: "center" }}>
              <Button
                variant="outline-danger"
                style={{ marginTop: "15px" }}
                onClick={() => nextpage(item)}
              >
                CONTRIBUTE NOW
              </Button>
            </div>
          </Card.Body>
        </Card>
      );
    })}
  </ReactElasticCarousel>
</div>

      <br></br>
      <br></br>
      <div className="textone" style={{ width: "100%", backgroundColor:"#0199FF",color:'white' }}>
        <div style={{fontSize:'25px'}}>"Fundraising Made Easy – Because Your Cause Deserves It !"</div>
        <div>
          <Button style={{color:'white'}} variant="outline-primary" onClick={handleShow}>
            START A FUNDRAISE
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title
                style={{
                  fontSize: "18px",
                  color: "#444444",
                }}
              >
                {" "}
                Need Urgent Funds ? For Your Medical Treatment{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  padding: "10px",
                  color: "#4b4b4b",
                }}
              >
                Our medical fundraising experts will connect in 24 hours to help
                you raise funds online
              </div>

              <Form style={{ padding: "20px" }}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    value={fullName}
                    placeholder="Name"
                    onChange={(e) => setfullName(e.target.value)}
                  />
                </FloatingLabel>

                <FloatingLabel
                  controlId="floatingInput"
                  label="Email address"
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    value={email}
                    placeholder="name@example.com"
                    onChange={(e) => setemail(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel
                  className="mb-3"
                  controlId="floatingPassword"
                  label="Mobile No"
                >
                  <Form.Control
                    type="tel"
                    value={phno}
                    placeholder="Mobile No"
                    onChange={(e) => setphno(e.target.value)}
                  />
                </FloatingLabel>

                <FloatingLabel
                  className="mb-3"
                  controlId="floatingSelect"
                  label="Cause"
                  placeholder="Cause"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => setcause(e.target.value)}
                  >
                    <option value="">Choose...</option>;
                    {CauseList?.map((item) => {
                      return (
                        <option value={item?.diseaseName}>
                          {item?.diseaseName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>

                <Button
                  variant="outline-danger"
                  className="m-"
                  type="submit"
                  onClick={(e) => reqFundraise(e)}
                >
                  Submit
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </Container>
  );
};

export default Activefundraiser;
