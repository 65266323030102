import React, { useEffect } from "react";
import "./Navigation.css";
import { NavLink, Link } from "react-router-dom";
import { useState } from "react";
import useRazorpay from "react-razorpay";
import axios from "axios";

import { Modal, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import FloatingLabel from "react-bootstrap/FloatingLabel";
const Navigation = () => {
  const userdetails = JSON.parse(localStorage.getItem("encodeUserInfo"));
  const signout = (e) => {
    e.preventDefault();
    localStorage.removeItem("encodeUserInfo");
    window.location.assign("/");
  };
  const [state, setState] = useState(false);
  const handleClick = () => {
    setState({ clicked: !state.clicked });
  };
  const [contribute, setContribute] = useState(false);

  const handleContribute = () => {
    setContribute({
      clicked: !contribute.clicked,
    });
  };

  /* ================================================================================================ */
  // donate now modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Razorpay = useRazorpay();

  /* ============================================================================================================== */

  const [fullName, setfullName] = useState("");
  const [phno, setphno] = useState("");
  const [email, setemail] = useState("");
  const [donatetype, setDonatetype] = useState("");
  const [amt, setamt] = useState(0);
  const [remark, setremark] = useState("");
  const [payid, setpayid] = useState("");
  const [expDate, setexpDate] = useState("");
  const [anonymous, setanonymous] = useState(false);
  const [data, setdata] = useState({});
  const [individualOrORG, setindividualOrORG] = useState("");

  /* ============================================================================================================== */

  const donarValidation = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: `/user/userDonationVal`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: {
          fullName: fullName,
          phno: phno,
          email: email,
          donateType: donatetype,
          amt: Number(amt),
          remark: remark,
          expDate: expDate,
          individualOrORG: individualOrORG,
          anonymous: anonymous,
          // volToken: volToken,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          setdata(res.data.data);
          posttransaction();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ============================================================================================================== */

  const addDonar = async () => {
    data.payid = payid;
    try {
      const config = {
        url: `/user/userDonation`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: data,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          // donarList();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ============================================================================================================== */

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const posttransaction = async () => {
    if (!donatetype || !amt) {
      return alert("please fill all the neccessary field");
    }
    try {
      const config = {
        data: {
          key: "rzp_test_5vCRZ6rLM2wGN4",
          amount: Number(amt) * 100,
          currency: "INR",
          name: "Vinayaka Trust",
          description: "Order Amount",
          image: "/Logo-1.jpg",
          email: email,
          handler: function (response) {
            alert(response.razorpay_payment_id);
            setpayid(response.razorpay_payment_id);
          },
          prefill: {
            email: email,
            phno: phno,
          },
        },
      };
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Fail to load");
        return;
      }
      const paymentObject = new Razorpay(config.data);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };

  /* ============================================================================================================== */
  useEffect(() => {
    if (payid) {
      addDonar();
    }
  }, [payid]);

  return (
    <div className="navigation">
      <nav class="navbar bg-body-tertiary">
        <div class="container-fluid">
          <div id="mobile" onClick={handleClick}>
            <i
              id="bar"
              className={state.clicked ? "fas fa-times" : "fas fa-bars"}
            ></i>
          </div>
          <NavLink class="navbar-brand text-decoration-none " to="/">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="./vinayakalogo.png"
                className="logo-img-s d-inline-block align-text-top"
                alt="Logo"
              />
              <span className="logo-text" style={{}}>
                {" "}
                SriVinayakaCharitableTrust
              </span>
            </div>
          </NavLink>

          <div>
            <ul
              id="navbar"
              className={state.clicked ? "#navbar active" : "#navbar"}
            >
              <li className="list">
                <NavLink
                  className="link-body-emphasis"
                  to="/"
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                >
                  Home
                </NavLink>
              </li>
              <li className="list">
                <NavLink
                  to="/aboutpage"
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                >
                  About Us
                </NavLink>
              </li>
              <li className="list">
                <NavLink
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                  to="/services"
                >
                  Services
                </NavLink>
              </li>
              <li className="list">
                <NavLink
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                  to="/gallery"
                >
                  Gallery
                </NavLink>
              </li>
              <li className="list">
                <NavLink
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                  to="/blog"
                >
                  Blog
                </NavLink>
              </li>

              <li className="list">
                <NavLink
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                  to="/volunteerform"
                >
                  Become a Volunteer
                </NavLink>
              </li>
              <li className="list">
                <NavLink
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                  to="/contactus"
                >
                  Contact Us
                </NavLink>
              </li>
              {userdetails ? (
                <div class="flex-shrink-0 dropdown">
                  <a
                    href="#"
                    class="d-block link-body-emphasis text-decoration-none dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="man.png"
                      alt="mdo"
                      width="32"
                      height="32"
                      class="rounded-circle"
                    />
                  </a>
                  <ul class="dropdown-menu text-small shadow">
                    <li>
                      <Link class="dropdown-item" to="/userprofile">
                        My Profile
                      </Link>
                    </li>

                    <li>
                      <Link class="dropdown-item" to="/donationhistory">
                        Donation History
                      </Link>
                    </li>

                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <span class="dropdown-item" onClick={(e) => signout(e)}>
                        Sign out
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </ul>
          </div>

          <NavLink
            className="nav-btn"
            style={{ textDecoration: "none", color: "#DA4453" }}
          >
            <button type="button" class=" button-1 btn-32" onClick={handleShow}>
              Donate
            </button>
          </NavLink>
        </div>
      </nav>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Donate</Modal.Title>
        </Modal.Header>{" "}
        <Modal.Body>
          <div
            style={{
              padding: "20px auto",
            }}
          >
            <Form.Floating className="mb-3">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <div>
                  <Button
                    variant="outline-success"
                    style={{
                      fontSize: "24px",

                      marginTop: "20px",
                      borderColor: "",
                      fontWeight: "bold",

                      width: "120px",
                      height: "50px",
                    }}
                    onClick={(e) => setamt(25)}
                  >
                    {" "}
                    ₹25
                  </Button>
                </div>
                <div>
                  <Button
                    variant="outline-success"
                    style={{
                      fontSize: "24px",
                      marginLeft: "30px",
                      marginTop: "20px",
                      borderColor: "",
                      fontWeight: "bold",

                      width: "120px",
                      height: "50px",
                    }}
                    onClick={(e) => setamt(50)}
                  >
                    ₹50
                  </Button>
                </div>
                <div>
                  <Button
                    variant="outline-success"
                    style={{
                      fontSize: "24px",
                      marginLeft: "30px",
                      marginTop: "20px",
                      borderColor: "",
                      fontWeight: "bold",

                      width: "120px",
                      height: "50px",
                    }}
                    onClick={(e) => setamt(70)}
                  >
                    {" "}
                    ₹70
                  </Button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  marginLeft: "-30px",
                }}
              >
                <div>
                  <Button
                    variant="outline-success"
                    style={{
                      fontSize: "24px",
                      marginLeft: "30px",
                      marginTop: "20px",
                      borderColor: "",
                      fontWeight: "bold",

                      width: "120px",
                      height: "50px",
                    }}
                    onClick={(e) => setamt(100)}
                  >
                    {" "}
                    ₹100
                  </Button>
                </div>
                <div>
                  <Button
                    variant="outline-success"
                    style={{
                      fontSize: "24px",
                      marginLeft: "30px",
                      marginTop: "20px",
                      borderColor: "",
                      fontWeight: "bold",

                      width: "120px",
                      height: "50px",
                    }}
                    onClick={(e) => setamt(200)}
                  >
                    ₹200
                  </Button>
                </div>
                <div>
                  <Button
                    variant="outline-success"
                    style={{
                      fontSize: "24px",
                      marginLeft: "30px",
                      marginTop: "20px",
                      borderColor: "",
                      fontWeight: "bold",

                      width: "120px",
                      height: "50px",
                    }}
                    onClick={(e) => setamt(500)}
                  >
                    {" "}
                    ₹500
                  </Button>
                </div>
              </div>
              <br></br>

              <div style={{ padding: "5px 10px 5px 10px " }}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Amount
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    value={amt}
                    onChange={(e) => setamt(e.target.value)}
                    required
                  />
                </InputGroup>
              </div>

              <Row>
                <Col>
                  <Form.Label style={{ marginLeft: "10px" }}>
                    Donation Freequency
                  </Form.Label>

                  <div
                    style={{
                      padding: "0px 10px 5px 10px ",
                      marginTop: "-2px",
                    }}
                  >
                    <Form.Select
                      aria-label="Default select example"
                      style={{ height: "37px", padding: "4px" }}
                      onChange={(e) => setDonatetype(e.target.value)}
                    >
                      <option value="">Select one</option>
                      <option value="onetime">One-time</option>
                      <option value="monthly">Monthly</option>
                      <option value="quaterly">Quaterly</option>
                      <option value="yearly">Annually</option>
                    </Form.Select>
                  </div>
                </Col>
                {donatetype == "monthly" ||
                donatetype == "quaterly" ||
                donatetype == "yearly" ? (
                  <Col>
                    <Form.Label style={{ marginLeft: "0px" }}>
                      Expiry Date
                    </Form.Label>
                    <Form.Control
                      style={{ marginLeft: "0px", width: "" }}
                      type="date"
                      onChange={(e) => setexpDate(e.target.value)}
                    />
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
{/* 
              <p className="fs-5 text-center mt-4">
                Are you representing an Individual or Organization?*
              </p>

              <div className="row mt-3">
                <div class=" col form-check  text-center">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    // checked
                    onChange={(e) => setindividualOrORG("individual")}
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    Individual
                  </label>
                </div>
                <div class=" col form-check  text-center">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    // checked
                    onChange={(e) => setindividualOrORG("organization")}
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    Organization
                  </label>
                </div>
              </div> */}
              <div className="">
                <div className="row mt-4">
                  <div class="mb-3 col">
                    <label for="exampleFormControlInput1" class="form-label">
                      Name / Organization Name
                    </label>
                    <input
                      type="text"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="Name"
                      onChange={(e) => setfullName(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col">
                    <label for="exampleFormControlInput1" class="form-label">
                      Email / Organization Mail
                    </label>
                    <input
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="name@example.com"
                      onChange={(e) => setemail(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col">
                    <label for="exampleFormControlInput1" class="form-label">
                      Phone no. / Organization Phone no.
                    </label>
                    <input
                      type="number"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="9876543210"
                      onChange={(e) => setphno(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: "5px 10px 5px 10px ",
                  fontSize: "16px",
                  color: "#111",
                }}
              >
                Message
              </div>

              <div style={{ padding: "5px 10px 5px 10px " }}>
                <FloatingLabel controlId="floatingTextarea2" label="Message">
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                    onChange={(e) => setremark(e.target.value)}
                  />
                </FloatingLabel>
              </div>

              {/* <div style={{ padding: "5px 10px 5px 10px " }}>
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      style={{
                        marginTop: "10px",
                      }}
                      label="Anonymous"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      onChange={() => setanonymous(!anonymous)}
                    />
                  </div>
                ))}
              </div> */}
            </Form.Floating>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
       
            <Button
              variant="outline-success"
              onClick={(e) => donarValidation(e)}
            >
              {" "}
              Donate
            </Button>
        
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Navigation;
